<template>
  <modal name="TransactionPopup" class="transaction" transition="pop-out" :adaptive="true">
    <div class="transaction-popup">
      <div class="transaction-popup__container">
        <p>
          FLYJETS’ FLYCalendar is designed to aggregate Flyer
          volume in order to facilitate the creation of discounted empty
          legs (typically discounted at <b>30 - 50%</b> of retail costs) and
          potential for group bookings. </p>

        <p>Electing to participate in FLYCalendar bookings for a period
          of one year provides access to this opt-in network of Flyers
          for any trips posted, and the potential to benefit from
          volume-based savings. </p>

          <p class="last">To purchase access as a FLYCalendar member for a yearly
          fee of <b>$500</b>, click <a class="transaction-popup__link" href="#">Initiate Transaction below.</a></p>
        <button class="buttonBlackOutline transaction-popup__button" @click="closePopup">
          <span class="text">Initiate Transaction</span>
        </button>
      </div>
      <div class="modal__close transaction-popup__close" @click="closePopup">
        <SvgIcon name="close-white"/>
      </div>
    </div>
  </modal>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import './TransactionPopup.styl'

export default {
  name: 'TransactionPopup',
  methods: {
    opened () {
      if (document.querySelector('html').classList.contains('d-mobile')) {
        const container = document.querySelector('.vm--container')
        const popup = document.querySelector('.bookRoutesPopup')

        container.classList.add('js-bookRoutesPopup')
        document.querySelector('body').appendChild(container)
        setTimeout(() => {
          popup.style.opacity = 1
        }, 300)
      }
    },
    closePopup () {
      this.$modal.hide('TransactionPopup')
      this.$modal.show('PaymentPopup');
    },
  },

  components: {
    SvgIcon,
  },
}
</script>
